<template>
  <div class="springAwakening2023_main">

    <nav-bar header-title="实体卡商城" backColor="#fff" :show-back="true" :header-back="newAppBack" ref="head" />
    <div class="headbg">
      <img src="https://img.chaolu.com.cn/ACT/temp-activity/card-mall/poster.png" alt="" />
      <div @click="showRule = true" class="rule-btn row-center-center">规则</div>
    </div>

    <div class="nav row-between-center">
      <div @click="changeNav(index)"
        :class="['nav-item', 'row-center-center', 'f30', 'fw6', activeKey === index ? 'active' : '']"
        v-for="item, index in navList" :key="index">{{ item }}</div>
    </div>

    <div class="goodsbox col-start-center">
      <template v-if="activeKey === 0">
        <div class="basebg" v-for="(item, key) in ticketEquityCardMap" :key="key">
          <!-- <div class="coner f34 fw6 row-start-center">{{ key }}元学生暑期私教课包</div> -->
          <div class="goods-model" v-for="(citem, index) in [item[0]]" :key="citem.itemId">
            <div class="pub_full">
              <div class="goods-img"><img :src="citem.picUrl" /></div>
              <div class="pub_onefull col-between-start">
                <div>
                  <div class="goods-name">{{ citem.itemName }}</div>
                  <div class="invalid-time" v-if="citem.expiryDateDescribe" v-html="citem.expiryDateDescribe"></div>
                  <div class="invalid-desc" v-if="citem.describe" v-html="citem.describe"></div>
                  <div class="label-line">
                    <div class="label-model" v-if="citem.discountRate">
                      <div class="scale">{{ citem.discountRate }}折</div>
                    </div>
                    <div class="label-model" v-if="citem.save">
                      <div class="scale">{{ citem.save }}</div>
                    </div>
                  </div>
                </div>
                <div class="row-between-center">
                  <div class="price-line row-start-end">
                    <span class="dl">¥</span>
                    <span class="price">{{ citem.salePrice }}</span>
                    <span v-if="citem.originalPrice" class="oldprice">¥{{ citem.originalPrice }}</span>
                  </div>
                  <div class="pub_avg line2">
                    <div class="buy" :class="{ gray: citem.itemStatus != 'CAN_BUY' }" @click="openBuyCity('ticketEquityCardMap', citem, key)">{{
                      buttonText(citem)
                    }}
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </template>
      <template v-if="activeKey === 1">
        <div class="basebg" v-for="(item, key) in equityCardMap" :key="key">
          <!-- <div class="coner f34 fw6 row-start-center">{{ key }}元学生暑期私教课包</div> -->
          <div class="goods-model" v-for="(citem, index) in [item[0]]" :key="citem.itemId">
            <div class="pub_full">
              <div class="goods-img"><img :src="citem.picUrl" /></div>
              <div class="pub_onefull col-between-start">
                <div>
                  <div class="goods-name">{{ citem.itemName }}</div>
                  <div class="invalid-time" v-if="citem.expiryDateDescribe" v-html="citem.expiryDateDescribe"></div>
                  <div class="invalid-desc" v-if="citem.describe" v-html="citem.describe"></div>
                  <div class="label-line">
                    <div class="label-model" v-if="citem.discountRate">
                      <div class="scale">{{ citem.discountRate }}折</div>
                    </div>
                    <div class="label-model" v-if="citem.save">
                      <div class="scale">{{ citem.save }}</div>
                    </div>
                  </div>
                </div>
                <div class="row-between-center">
                  <div class="price-line row-start-end">
                    <span class="dl">¥</span>
                    <span class="price">{{ citem.salePrice }}</span>
                    <span v-if="citem.originalPrice" class="oldprice">¥{{ citem.originalPrice }}</span>
                  </div>
                  <div class="pub_avg line2">
                    <div class="buy" :class="{ gray: citem.itemStatus != 'CAN_BUY' }"  @click="openBuyCity('equityCardMap', citem, key)">{{
                      buttonText(citem)
                    }}
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </template>

      <!-- <div class="bottom-tips row-center-center"><i></i><span>已经滑到底部啦</span><i></i></div> -->
    </div>
    <van-popup v-model="showRule" position="bottom" safe-area-inset-bottom class="pop-rule">
      <div class="pop-rule-title">
        {{ rules.title }}
        <van-icon name="cross" class="pop-rule-close" color="#fff" @click="showRule = false" />
      </div>
      <div class="pop-rule-content">
        <div v-for="(item, index) in rules.content" :key="index">{{ item }}</div>
      </div>
    </van-popup>

    <van-popup v-model="showCity" position="bottom" safe-area-inset-bottom class="popcity">
      <div class="buy-title">
        确认下单
        <van-icon name="cross" class="buy-close" @click="showCity = false" />
      </div>
      <div class="cbox">
        <div class="buy-title2">购买商品</div>
        <div class="goods-line">
          <img :src="buyItem.picUrl" class="goods-img" />
          <div class="goods-info">
            <div class="goods-name">{{ buyItem.itemName }}</div>
            <div class="time" v-if="buyItem.expiryDateDescribe">{{ buyItem.expiryDateDescribe }}</div>
            <div class="label" v-if="buyItem.save">{{ buyItem.save }}</div>
          </div>
        </div>
        <div class="buy-title2">选择购买卡城市</div>
        <div class="buy-tips">选择指定城市卡，选择后无法修改、退换。</div>
        <div class="city-list">
          <div v-for="(item, index) in cityList" :key="index" class="city-model"
            :class="{ 'city-active': cityIndex === index }" @click="chooseCity(item, index)">
            {{ item.name }}
            <div class="checkbox"></div>
          </div>

        </div>
        <div class="row-between-center">
          <div class="buy-title2">购买数量</div>
          <van-stepper v-model="goodsNum" min="1" max="10" />
        </div>
        <div class="store-list">
          <div class="buy-title2">选择兑换门店</div>
          <div class="buy-tips" style="color: #F9624E;">购买后将发放“实体卡兑换券”至您的账户，请选择方便前往的门店，找教练核销兑换。</div>
          <div @click="openStore" class="store-box row-between-center">
            <p class="f30 fw6">{{ venueObj.label ? venueObj.label : '请选择前往领取的门店'}}</p>
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
      <div class="next" @click="next">下一步</div>
    </van-popup>
    <van-popup v-model="showSure" position="center" class="popsure">
      <div class="sure-title">提示</div>
      <div class="sure-content">您当前购买的自助健身卡，仅限<span v-if="cityIndex != undefined">{{
        cityList[cityIndex].name
      }}</span>市所有门店使用，请确认后再付款！
      </div>
      <div class="sure-sub" @click="next">我知道了</div>
    </van-popup>

    <CityVenue v-if="curCityId" ref="refCityVenue" :disableCityId="disableCityIdArr" :cityId="curCityId" @setVenue="setVenue" />
    <common-share :value="showShare" :shareParams="shareParams" @share-success="showShare = false"
      :shareItem="shareItem" :miniSharePoster="shareItem.length === 3 && appTypeStr === 'mini'">
      <template slot="createDom">

      </template>
    </common-share>
  </div>
</template>
<script>
import { newAppBack, initBack, appPay, appPaySuccess } from '@/lib/appMethod'
import navBar from '@/components/nav-bar/nav-bar'

import CityVenue from "@/components/cl-city-venue/cityVenue.vue"
import userMixin from '@/mixin/userMixin'
import headerMixin from '@/mixin/headerMixin'
import commonShare from '@/components/commonShare';
import { isEmpty } from '@/lib/utils'
import wx from 'weixin-js-sdk';

const activityNo = 'physicalMall';
const webUrl = `${window.location.origin}/#/temp-activity/card-mall`
export default {
  components: {
    navBar,
    CityVenue,
    commonShare
  },
  mixins: [userMixin, headerMixin],
  data() {
    return {
      showShare: false,

      showRule: false,
      showSure: false,

      inviteTeacherId: '', // 教练id
      inviteUserInfo: {}, // 邀请人信息

      navList: ['自助健身卡', '团课卡'],
      activeKey: 0,

      cityList: [
        { name: '福州市', cityId: '3' },
        { name: '厦门市', cityId: '036652995ac5451fbb6791c3491e1c2d' },
        { name: '广州市', cityId: '818447834079563776' }
      ],
      // cardTitle: [
      //   '', '送朋友，一起来运动！', '送伴侣，健身更迷人！', '送亲人，健康添福气！'
      // ],
      shareParams: {
        title: '买卡送好友，一起来运动',
        miniImage: '',
        path: `/pages/webView/index?webUrl=${webUrl}&userId=1&token=1`,
        miniProgramType:
          window.location.origin.indexOf('web.chaolu.com') > -1
            ? '0'
            : '2', // 正式0，开发1，体验2
        url: 'url',
        multiple: '0.95',
        userName: 'gh_7f5b57b6a76e',
      },
      shareItem: ['posterfriend', 'postermessage', 'save'],

      showCity: false,
      cityIndex: undefined,
      curCityId: '',

      priceKey: 0, // 自助卡第几类
      cardType: 'ticketEquityCardMap',
      goodsNum: 1,

      equityCardMap: [], // 团课月卡
      ticketEquityCardMap: [], // 自助卡二重数组

      rules: {
        title: '活动规则',
        content: [
          '1、购卡时仅支持现金支付，不支持使用余额、礼品卡进行购买。',
          '2、需区分城市使用健身权益卡和团课月卡，权益仅能在购买选定的城市使用。',
          '3、用户购买后，系统会发放“实体卡兑换券”，可根据购买时选择的门店，到门店前台找工作人员进行“兑换券核销与实体卡领取”。',
          '4、用户可自主选择激活生效时间，激活开卡当日即生效，为权益享受第一天',
          '5、权益卡仅限本人使用，不可转赠，不可用于商业用途。',
          '6、用户不得以不正当、不诚信的方式获取/使用权益卡，否则超鹿运动有权撤销用户付费权益卡资格并有权收回用户已经获得的权益（包括追偿已使用部分）。',
          '如有其他疑问，可咨询在线客服或致电客服，客服咨询热线：400-700-5678。'
        ]
      },
      stickyHeight: 0,

      buyItem: {},
      venueObj: {}, // 选择的门店
    }
  },
  methods: {
    newAppBack,
    isEmpty,
    buttonText(item = {}) {
      const status = item.itemStatus;
      const limit = item.hasBuyLimit;
      const json = {
        'NOT_STARTED': '待开启',
        'EXPIRED': '已结束',
        'SOLD_OUT': '已售罄',
        'HAVE_BUY': '已购买',
        'CAN_BUY': '马上抢',
        'NOT_BUY': '不可购买'
      }
      let text = json[status] || '马上抢';
      if (status == 'CAN_BUY' && limit) {
        text = '限量抢';
      }
      return text;
    },
    chooseCity(item, index) {
      if (this.cityIndex !== index) {
        this.venueObj = {}
      }
      this.cityIndex = index
      this.curCityId = item.cityId

      this.disableCityIdArr = this.cityList.filter((item) => item.cityId !== this.curCityId).map((ktem) => ktem.cityId)
    },
    setVenue(item) {
      this.venueObj = item;
    },
    openStore() {
      if (!this.curCityId) {
        this.$toast('请先选择购买城市')
        return
      }
      this.$refs.refCityVenue.popShow = true;
    },
    next() {

      if (!this.curCityId) {
        this.$toast('请先选择购买城市')
        return
      }
      if (!this.venueObj.id) {
        this.$toast('请先选择领取的门店')
        return
      }

      const payitem = this[this.cardType][this.priceKey][this.cityIndex];
      this.buyCard(payitem, 22);
    },
    changeNav(i) {
      this.activeKey = i
    },
    getGoodList() {
      const toast = this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: "spinner",
        message: '加载中...',
      })
      this.$axios.post(`${this.baseURLApp}/anniversary/sale/getItemList`, this.params).then(res => {
        const r = res.data;
        this.ticketEquityCardMap = r.ticketEquityCardMap  // 自助
        this.equityCardMap = r.equityCardMap // 团课
        toast.clear()
      }).catch(r => {
        toast.clear()
      })
    },
    async buyCard(item, type) {

      if (item.itemStatus !== 'CAN_BUY') {
        this.$toast('很遗憾，您暂不满足购买条件。')
        return
      }
      const res = await this.checkCanBuy(item);

      if (res.code === '0') {
        this.$toast(res.msg)
        setTimeout(() => {
          this.getGoodList()
        }, 2500);
        return
      }

      // 服务端需要 做个转换
      const obj = JSON.parse(JSON.stringify(item))
      obj.itemId = obj.isFlashSale ? obj.itemId.split('-')[0] : obj.itemId

      this.payAction(obj, type)
    },
    payAction(info, type) {
      let url = `/pages/webView/index?webUrl=${webUrl}&userId=1&token=1`
      let payData = {
        subject: info.itemName,
        extendsJson: {
          appVersion: '2.1.11',
          seriesName: '实体卡商城',
          venueId: this.venueObj.id,
        },
        totalAmount: (info.salePrice * this.goodsNum).toString(),
        venueId: '31',
        goodsNum: this.goodsNum,
        isApp: '1',
        type: type,
        userId: this.params.userId,
        token: this.params.token,
        extendsType: '4',
        mouldId: info.itemId,
        whetherPrivacy: 0,
        originalPriceStr: '',
        title: info.itemName,
      }
      if (this.appTypeStr === 'mini') {
        payData.wxPayType = 'applet'
      }
      appPay(payData, url);
      console.log(payData, url);
      if (this.appTypeStr !== 'mini') {
        appPaySuccess().then(() => {
          this.showCity = false
          this.paySuccess();
          this.$toast('支付成功');
        })
      }
    },
    paySuccess() {
      setTimeout(() => {
        this.getGoodList();
      }, 500)
    },
    checkCanBuy(info) {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      return this.$axios.post(this.baseURLApp + '/anniversary/sale/checkCanBuy', {
        ...this.params,
        itemId: info.itemId
      }).then((r) => {
        this.$toast.clear()
        return r
      }).catch((r) => {
        this.$toast.clear()
        return r
      })
    },
    openBuyCity(type, item, key) {
      this.cityIndex = undefined
      this.curCityId = ''
      this.goodsNum = 1
      this.priceKey = key
      this.cardType = type
      this.showCity = true;
      this.buyItem = item
    },
    miniShare() {
      wx.miniProgram.postMessage({
        data: {
          type: 'share',
          shareImage: this.shareParams.miniImage,
          shareTitle: this.shareParams.title,
          shareUrl: this.shareParams.path,
        },
      })
    }
  },
  mounted() {
    setTimeout(() => {
      this.stickyHeight = this.$refs.head.$el.offsetHeight
    }, 1000)
  },
  async created() {
    initBack();
    this.inviteTeacherId = this.$route.query.teacherId || this.$route.query.inviteTeacherId
    await this.$getUserId();
    this.params = {
      activityNo,
      userId: this.userId,
      token: this.token,
      hasWx: true
    }

    this.getGoodList()
    appPaySuccess().then(() => {
      this.showCity = false
      this.paySuccess();
    })
    this.miniShare()
  },

}
</script>
<style lang="less" scoped>
.springAwakening2023_main {
  min-height: 100vh;
  background: #F38933;
  padding-bottom: 60px;
  box-sizing: border-box;

  div {
    box-sizing: border-box;
  }

  img {
    vertical-align: top;
    width: 100%;
  }

  .leftbg .van-icon-arrow-left {
    width: 64px;
    height: 64px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    text-align: center;
    line-height: 64px;
    font-size: 38px !important;
  }

  .share {
    width: 46px;
    height: 46px;
  }

  .headbg {
    position: relative;
  }

  .nav {
    width: 500px;
    height: 80px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 40px;
    margin: 30px auto 40px;
    padding: 0 8px;

    .nav-item {
      width: 242px;
      height: 64px;
      color: #fff;

      &.active {
        background: linear-gradient(0deg, #FFFFFF 0%, #FFE9E3 100%);
        border-radius: 32px;
        color: #F9624E;
      }
    }
  }

  .title-head-img {
    height: 57px;
    width: 427px;
    position: absolute;
    bottom: 38px;
    left: 161px;
  }

  .rule-btn {
    width: 88px;
    height: 56px;
    font-size: 24px;
    color: #FFFFFF;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 28px 0 0 28px;
    position: absolute;
    top: 420px;
    right: 0;
  }

  .bind-coach {
    width: 686px;
    height: 88px;
    background: linear-gradient(88deg, #FDD9A4 0%, rgba(252, 211, 153, 0) 100%, rgba(253, 217, 164, 0) 100%);
    border-radius: 124px;
    margin: 0 auto 24px;
    padding: 0 18px;

    .avator {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      border: 2px solid #fff;
      margin: 0 24px 0 0;
    }
  }

  .goods-title {
    height: 100px;
    margin-top: 40px;
  }

  .tofu {
    padding: 16px 32px 0;
    position: relative;
    background: #FF9926;

    .tm {
      width: 216px;
      height: 72px;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      color: #FFFFFF;
      margin-bottom: 16px;

      &.big {
        width: 334px;
      }

      &.active {
        color: #311C07;
        background: linear-gradient(180deg, #FFFFFF 0%, #FFEDB8 100%);
      }
    }


  }

  .goodsbox {
    width: 686px;
    margin: 0px auto 46px;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    position: relative;
    padding: 28px 24px 12px;

    .classimg {
      height: 58px;
      width: fit-content;
      padding: 0 68px;
      background-image: url(https://img.chaolu.com.cn/ACT/normal-coach-mall/title-bg-l.png), url(https://img.chaolu.com.cn/ACT/normal-coach-mall/title-bg.png);
      background-size: 68px 58px;
      background-position: left top, right top;
      background-repeat: no-repeat;
      color: #8D3500;
    }

    .seerule {
      width: 160px;
      height: 42px;
      margin: 28px auto 44px;
    }

    .see-title {
      width: 452px;
      height: 40px;
      margin: 0 auto 32px;
    }

    .basebg {
      width: 100%;
    }

    .pdt {
      padding-top: 94px;
    }


    .coner {
      word-break: keep-all;
      position: absolute;
      top: -10px;
      left: 0;
      padding: 0 24px;
      width: 384px;
      box-sizing: border-box;
      font-size: 30px;
      height: 60px;
      background: url("https://img.chaolu.com.cn/ACT/temp-activity/student-mall/bg_title.png") no-repeat center center;
      background-size: 100% 100%;

      color: #9B270C;
    }

    .goods-model {
      background: #fff;
      border-radius: 16px;
      margin-bottom: 20px;
      padding: 26px;
      //height: 292px;

      .goods-img {
        width: 220px;
        height: 180px;
        background-color: rgba(255, 255, 255, 0.7);
        margin-right: 30px;
        overflow: hidden;
        flex-shrink: 0;
        img {
          object-fit: cover;
            border-radius: 10px;
        }
      }

      .goods-name {
        font-weight: bold;
        color: #242831;
        font-size: 30px;
        padding-top: 2px;
        line-height: 30px;
      }

      .describe {
        width: 96px;
        height: 36px;
        border-radius: 4px;
        color: #FFFFFF;
        font-weight: bold;
        font-size: 20px;
        background: #FF6C01;
        margin-bottom: 20px;
      }

      .label-line {
        margin: 17px 0 16px;
        white-space: nowrap;
        display: flex;

        .label-model {
          padding: 0 10px;
          height: 30px;

          background: linear-gradient(90deg, #FFE8B5 0%, #FFD7C3 100%);
          border-radius: 4px;
          color: #B12E1D;
          font-weight: bold;
          font-size: 20px;
          margin-right: 12px;
          line-height: 1;
          display: flex;
          align-items: center;

          .scale {
            transform: scale(0.9);
            transform-origin: 50% 50%;
          }
        }

        .limit {
          padding: 0;
          background: #79AF52;
        }
      }

      .invalid-time {
        color: #3C454E;
        font-size: 20px;
        line-height: 16px;
        margin-top: 16px;
      }
      .invalid-desc {
        font-size: 20px;
        color: #F9624E;
        margin-top: 16px;
      }

      .price-line {

        .dl {
          font-size: 22px;
          color: #F03C18;
          font-weight: bold;
          margin-right: 4px;
        }

        .price {
          font-size: 32px;
          color: #F03C18;
          font-weight: bold;
          margin-right: 9px;
          //position: relative;
          line-height: 32px;
          //top: 2px;
        }

        .oldprice {
          text-decoration: line-through;
          color: #6C727A;
          font-size: 20px;
        }
      }

      .line2 {
        align-items: center;

        //margin-top: 14px;
        .buy {
          width: 136px;
          height: 50px;
          background: linear-gradient(0deg, #F35947 0%, #FBA27B 100%);
          border-radius: 25px;
          color: #fff;
          font-size: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
        }

        .gray {
          color: #6C727A;
          background: #DDDDDD;
          box-shadow: none
        }
      }
    }
  }

  .pop-rule {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;

    .pop-rule-title {
      height: 132px;
      font-weight: bold;
      background: linear-gradient(180deg, #FFDBD9 0%, #FCFEFF 100%);
      font-size: 32px;
      text-align: center;
      line-height: 130px;
      position: relative;

      .pop-rule-close {
        position: absolute;
        right: 32px;
        top: 38px;
        width: 44px;
        height: 44px;
        line-height: 44px;
        border-radius: 50%;
        background: rgba(39, 40, 49, 0.2);
        font-size: 28px;
      }
    }

    .pop-rule-content {
      color: #242831;
      font-size: 26px;
      padding: 0 52px 60px;
      max-height: 720px;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      white-space: pre-line;

      div {
        margin-bottom: 46px;
        line-height: 1.6;
      }
    }
  }


  .popcity {
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;

        .buy-title {
            font-weight: bold;
            color: #242831;
            font-size: 32px;
            border-bottom: 1px solid #EEEEEE;
            height: 97px;
            line-height: 97px;
            padding: 0 32px;
            text-align: center;
            position: relative;
        }
        .buy-close {
            font-size: 36px;
            position: absolute;
            right: 32px;
            top: 50%;
            transform: translateY(-50%);
            color: #242831;
        }

        .cbox {
            padding: 37px 32px 40px;
            border-bottom: 1px solid #eee;
            max-height: 72vh;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
        }

        .buy-tips {
            font-size: 24px;
            color: #9AA1A9;
            margin: 18px 0 28px;
        }

        .goods-line {
            display: flex;
            margin: 28px 0 60px;

            .goods-img {
                height: 160px;
                width: auto;
            }

            .goods-info {
                flex: 1;
                margin-left: 30px;

                .goods-name {
                    font-size: 34px;
                    color: #242831;
                    font-weight: bold;
                    line-height: 1;
                    margin-top: 10px;
                }

                .time {
                    font-size: 22px;
                    color: #242831;
                    margin: 20px 0;
                    line-height: 1;
                }

                .label {
                    display: flex;
                    height: 30px;
                    line-height: 32px;
                    background: linear-gradient(90deg, #FFE8B5 0%, #FFD7C3 100%);
                    padding: 0 13px;
                    margin-bottom: 15px;
                    font-size: 20px;
                    color: #B12E1D;
                    font-weight: bold;
                    border-radius: 4px;
                    float: left;
                }
            }
        }

        .buy-title2 {
            color: #242831;
            font-size: 28px;
            font-weight: bold;
            line-height: 1;
        }

        .city-list {
            padding: 0 0 55px;

            .city-model {
                margin-top: 24px;
                height: 128px;
                background: #F5F5F5;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 32px;
                font-weight: bold;

                &:first-of-type {
                    margin-top: 0
                }

                .checkbox {
                    width: 32px;
                    height: 32px;
                    border: 1px solid #DDD;
                    border-radius: 50%;
                    margin-right: 8px;
                }
            }

            .city-active {
                border: 4px solid #242831;

                .checkbox {
                    width: 48px;
                    height: 48px;
                    background: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/spring2023/check.png);
                    background-size: 100% 100%;
                    border: none;
                    margin: 0;
                }
            }
        }
        .store-list {
          margin: 80px 0 0;
          .store-box {
            width: 100%;
            height: 128px;
            background: #F5F5F5;
            border-radius: 8px;
            padding: 0 32px;
          }
        }
        .next {
            line-height: 96px;
            background: #FFDE00;
            border-radius: 8px;
            margin: 34px 32px;
            font-weight: bold;
            text-align: center;
        }
    }

  .popsure {
    width: 590px;
    border-radius: 12px;
    padding: 50px 40px;

    .sure-title {
      color: #242831;
      font-size: 36px;
      font-weight: bold;
      text-align: center;
    }

    .sure-content {
      color: #3C454E;
      font-size: 24px;
      line-height: 1.6;
      margin: 36px 0 48px;
      text-align: center;

      span {
        color: #F03C18;
      }
    }

    .sure-sub {
      border-radius: 8px;
      background: #FFDE00;
      color: #000;
      font-weight: bold;
      font-size: 32px;
      line-height: 96px;
      text-align: center;
    }
  }




  .bottom-tips {
    color: #FFFFFF;
    font-size: 22px;

    i {
      width: 60px;
      height: 1px;
      background: #FFFFFF;
    }

    span {
      margin: 0 30px;
    }
  }

  /deep/ .canvas-box {
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;

    .create-img {
      width: 100% !important;
    }
  }

//  /deep/ .van-stepper--round .van-stepper__plus {
//           background-color: #FFFFFF;
//           color: #333;
//         }
}</style>
